/* .App {
  text-align: center;
} */

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 600px;
  background-color: white;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profile-card {
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 14px #00000029;
  border-radius: 10px;
}


.stats-card {
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 14px #00000029;
  border-radius: 10px;
  display: flex;
  height:75px;
  flex-direction: row;
  justify-content: space-around;
  background: rgb(2,0,36);
  background: rgb(102,100,255);
background: linear-gradient(90deg, rgba(102,100,255,1) 0%, rgba(116,55,255,0.6033773016997167) 100%);
}